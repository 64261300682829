/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { AuthPage } from "../Pages/Auth/AuthPage";
import {
  RouterProvider,
} from "react-router-dom";
import { auth } from "../hooks/useAuth";
import { onAuthStateChanged } from "firebase/auth";
import { useAppDispatch, useAppSelector } from "../store";
import { login, refreshToken, selectAuthState } from "../store/Auth/authStore";
import { userLogOut } from "../store/Auth/thunks";
import { router } from "./router";
import { Notification } from "../Components/Notification/Notification";

const AdminRouter = () => {
  return <RouterProvider router={router} />;
};

export const RouteOnAuth = () => {
  const { profile } = useAppSelector(selectAuthState);
  const dispatch = useAppDispatch();
  const [loading, setLoading] = React.useState<boolean>(true);

  React.useEffect(() => {
    const listen = onAuthStateChanged(auth, async (user) => {
      if (user) {
        const accessToken = await user.getIdToken();
        dispatch(refreshToken(accessToken));
        dispatch(
          login({
            email: user.email ? user.email : "exempel@gmail.com",
            uid: user.uid,
          })
        );
      } else dispatch(userLogOut());
      setLoading(false);
    });
    return () => {
      listen();
    };
  }, []);

  if (loading) return <div />;

  return (
    <>
      {!!profile && (
        <React.Suspense fallback={<div />}>
          <AdminRouter />
          <Notification />
        </React.Suspense>
      )}
      {!profile && (
        <React.Suspense fallback={<div />}>
          <AuthPage />
        </React.Suspense>
      )}
    </>
  );
};
