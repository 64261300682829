import { createAsyncThunk } from "@reduxjs/toolkit";
import { agent, encodeGetParams } from "../../api";
import { apiUrlV1 } from "../../config/config";
import {
  IReqWheelList,
  IDatabaseWheel,
  IFormPrizeCreate,
  IReqProfilesWheel,
  IProfilesWheel,
  IGlobalProfile,
  IWheelConfige,
  ITranslationProfile,
  IPrizeResponse,
} from "../../entities/wheelGame";
import { addNotification } from "../Notification/notificateStore";

const DEFAULT_URL = `${apiUrlV1}/organization/game/fortunewheel/`;

export const getPrizeWheel = createAsyncThunk(
  "wheel/list",
  async (): Promise<IDatabaseWheel[]> => {
    const query = encodeGetParams({ page: 1, limit: 250 });
    const req = await agent.get(`${DEFAULT_URL}prize/list${query}`);
    if (req.ok) {
      const res: IReqWheelList = await req.json();
      return res.gameEntities;
    }
    return [];
  }
);

export const getPrizeById = createAsyncThunk(
  "wheel/get-prize-by-id",
  async (id: string) => {
    const res = await agent.get(`${DEFAULT_URL}prize/${id}`);
    if (res.ok) {
      const result = await res.json();
      return result.prize as IPrizeResponse;
    }
  }
);

export const getProfilesWheel = createAsyncThunk(
  "wheel/get-profile",
  async (): Promise<IProfilesWheel[]> => {
    const res = await agent.get(`${DEFAULT_URL}profile/list/`);

    if (res.ok) {
      const body = (await res.json()) as IReqProfilesWheel;
      return body.list;
    }
    return [];
  }
);

export const postPrizeWheel = createAsyncThunk(
  'wheel/post-prize-wheel',
  async (props: IFormPrizeCreate, thunkApi) => {
    const body = {
      name: props.name.trim(),
      config: {
        jackpot: (props.jackpot === 'true'),
      },
      bonusId: Number(props.bonusId),
      langs: props.language,
    };
    try {

      const res = await agent.post(`${DEFAULT_URL}prize`,
        { body: JSON.stringify(body), }
      )

      if (res.ok) {
        thunkApi.dispatch(addNotification({ status: 'success', text: "Successfully saved" }))
        const result = await res.json() as { id: number }
        thunkApi.dispatch(getPrizeWheel());
        thunkApi.dispatch(getPrizeById(`${result.id}`));

        return result.id;
      }
      if (!res.ok) {
        const result = await res.json() as { message: string }
        thunkApi.dispatch(addNotification({ status: 'error', text: result.message }))
        throw result.message;

      }
    } catch (error) {
      throw error;
    }
  }
)

export const patchPrizeWheel = createAsyncThunk(
  'wheel/patch-prize-wheel',
  async (props: { data: IFormPrizeCreate, id: number }, thunkApi) => {
    const { data, id } = props;
    const body = {
      name: data.name,
      config: {
        jackpot: (data.jackpot === 'true'),
      },
      bonusId: Number(data.bonusId),
      langs: data.language,
    };

    try {
      const res = await agent.patch(`${DEFAULT_URL}prize/${id}`,
        { body: JSON.stringify(body), }
      )
      if (res.ok) {
        thunkApi.dispatch(addNotification({ status: 'success', text: "Successfully saved" }))
        thunkApi.dispatch(getPrizeById(`${id}`));
        thunkApi.dispatch(getPrizeWheel());
      }
      if (!res.ok) {
        const result = await res.json() as { message: string }
        thunkApi.dispatch(addNotification({ status: 'error', text: result.message }))
        throw result.message;
      }
    } catch (error) {
      throw error;

    }
  }
)

export const postPrizeWheelTranslation = createAsyncThunk('wheel/patch-prize-wheel-translation',
  async (props: {
    data: any, id: number
  }, thunkApi) => {
    const res = await agent.post(`${DEFAULT_URL}prize/upload-translation/${props.id}`, {
      body: JSON.stringify(props.data)
    })
    if (res.ok) {
      thunkApi.dispatch(addNotification({ status: 'success', text: "Successfully saved" }))
      thunkApi.dispatch(getPrizeById(`${props.id}`));
    }

  })

export const deletePrizeById = createAsyncThunk(
  "wheel/delete-prize",
  async (id: number, thunkApi) => {
    const response = await agent.delete(`${DEFAULT_URL}prize/${id}`);
    if (response.ok) {
      thunkApi.dispatch(addNotification({ status: 'success', text: "Successful removal" }))
      thunkApi.dispatch(getPrizeWheel());
    }
  }
);

interface IProfile {
  global: IGlobalProfile | null;
  config: IWheelConfige[];
  translations: ITranslationProfile[];
}

export const getProfile = createAsyncThunk(
  `wheel-profile/get`,
  async (profileId: number) => {
    const [global, config, translation] = await Promise.all([
      await agent.get(`${DEFAULT_URL}profile/${profileId}`),
      await agent.get(
        `${DEFAULT_URL}profile/config/list?profileId=${profileId}`
      ),
      await agent.get(`${DEFAULT_URL}profile/translation/list/${profileId}`),
    ]);
    let response: IProfile = { global: null, config: [], translations: [] };
    if (global.ok) {
      response = { ...response, global: await global.json() };
    }
    if (config.ok) {
      const data = (await config.json()) as {
        count: number;
        list: IWheelConfige[];
      };
      response = { ...response, config: data.list };
    }
    if (translation.ok) {
      const data = (await translation.json()) as {
        list: ITranslationProfile[];
      };
      response = { ...response, translations: data.list };
    }

    return response;
  }
);

export const createGlobalProfile = createAsyncThunk(
  "wheel/create-profile",
  async (data: IGlobalProfile, thunkApi) => {
    try {
      const res = await agent.post(`${DEFAULT_URL}profile`, {
        body: JSON.stringify(data),
      });

      if (res.ok) {
        const result = (await res.json()) as { profileId: number };
        thunkApi.dispatch(getProfilesWheel());
        return result.profileId;
      }

      if (!res.ok) {
        const result = await res.json() as { message: string }
        thunkApi.dispatch(addNotification({ status: 'error', text: result.message }))
        throw result.message;

      }
    } catch (error) {
      throw error;
      // return thunkApi.rejectWithValue(error);
    }
  }
);

export const updateGlobalProfile = createAsyncThunk(
  "wheel/update-profile",
  async (data: IGlobalProfile, thunkApi) => {
    const body = {
      triggerId: data.triggerId,
      name: data.name,
      primaryLang: data.primaryLang,
      langs: data.langs,
      status: data.status,
    };
    try {
      const res = await agent.patch(`${DEFAULT_URL}profile/${data.id}`, {
        body: JSON.stringify(body),
      });

      if (res.ok) {
        const body = (await res.json()) as { profileId: number };
        thunkApi.dispatch(addNotification({ status: 'success', text: "Successfully saved" }))
        thunkApi.dispatch(getProfile(body.profileId));
        thunkApi.dispatch(getProfilesWheel());
      }

      if (!res.ok) {
        const result = await res.json() as { message: string }
        thunkApi.dispatch(addNotification({ status: 'error', text: result.message }))
        throw result.message;

      }
    } catch (error) {
      throw error;
    }
  }
);

export const getConfigWheelById = createAsyncThunk(
  "wheel/config-wheel",
  async (id: number) => {
    const query = encodeGetParams({ profileId: id });
    const res = await agent.get(`${DEFAULT_URL}profile/config/list${query}`);

    if (res.ok) {
      const body = (await res.json()) as {
        count: number;
        list: IWheelConfige[];
      };
      if (body.list.length > 12) {
        return body.list.slice(0, 12);
      } else {
        return body.list;
      }
    }
    return [];
  }
);

export const setConfigWheel = createAsyncThunk(
  "wheel/create-config-wheel",
  async (props: IWheelConfige[], thunkApi) => {
    const req = await agent.post(`${DEFAULT_URL}profile/config`, {
      body: JSON.stringify({ configList: props }),
    });
    if (req.ok) {
      const res = (await req.json()) as { id: number };
      thunkApi.dispatch(addNotification({ status: 'success', text: "Successfully saved" }))
      thunkApi.dispatch(getProfile(res.id));
    }
  }
);

export const updateConfigWheelById = createAsyncThunk(
  "wheel/update-config-wheel",
  async ({ data, id }: { id: number; data: IWheelConfige[] }, thunkApi) => {
    const req = await agent.patch(`${DEFAULT_URL}profile/config/${id}`, {
      body: JSON.stringify({ configList: data }),
    });
    if (req.ok) {
      const res = (await req.json()) as { id: number };
      thunkApi.dispatch(addNotification({ status: 'success', text: "Successfully saved" }))
      thunkApi.dispatch(getProfile(res.id));
    }
  }
);

export const getPopupSettings = createAsyncThunk(
  "wheel/get-popup-list",
  async (id: number) => {
    const res = await agent.get(`${DEFAULT_URL}profile/translation/list/${id}`);

    if (res.ok) {
      const body = await res.json();
      return body.list;
    }
    return [];
  }
);

export const postPopupTranslation = createAsyncThunk(
  "wheel/post-popup-list",
  async (props: { data: ITranslationProfile, profileId: number }, thunkApi) => {
    const req = await agent.post(`${DEFAULT_URL}profile/translation/${props.profileId}`, {
      body: JSON.stringify(props.data),
    })
    if (req.ok) {
      thunkApi.dispatch(addNotification({ status: 'success', text: "Successfully saved" }))
      thunkApi.dispatch(getProfile(props.profileId));
    }
  }
)

export const patchPopupTranslation = createAsyncThunk(
  "wheel/patch-popup-list",
  async (props: { data: ITranslationProfile, profileId: number }, thunkApi) => {
    const req = await agent.patch(`${DEFAULT_URL}profile/translation/${props.profileId}`, {
      body: JSON.stringify(props.data),
    })
    if (req.ok) {
      thunkApi.dispatch(addNotification({ status: 'success', text: "Successfully saved" }))
      thunkApi.dispatch(getProfile(props.profileId));
    }
  }
)

// export const setTranslations = createAsyncThunk(
//   "wheel/set-popup-list",
//   async (data: ITranslationProfile[], thunkApi) => {
//     await Promise.all(
//       data.map(async (item) => {
//         const response = await agent.post(
//           `${DEFAULT_URL}profile/translation/${item.profileId}`,
//           {
//             body: JSON.stringify(item),
//           }
//         );

//         if (!response.ok) {
//           throw new Error(`HTTP error ${response.status}`);
//         }
//       })
//     );
//     thunkApi.dispatch(getProfile(data[0].profileId));
//   }
// );

// export const updateTranslations = createAsyncThunk(
//   "wheel/update-popup-list",
//   async (data: ITranslationProfile[], thunkApi) => {
//     const patch = data.filter((item) => !!item.id);

//     await Promise.all(
//       patch.map(async (item) => {
//         const response = await agent.patch(
//           `${DEFAULT_URL}profile/translation/${item.profileId}`,
//           {
//             body: JSON.stringify(item),
//           }
//         );

//         if (!response.ok) {
//           throw new Error(`HTTP error ${response.status}`);
//         }
//       })
//     );

//     const post = data.filter((item) => !item.id);
//     if (post.length !== 0) {
//       thunkApi.dispatch(setTranslations(post));
//     }

//     thunkApi.dispatch(getProfile(data[0].profileId));
//   }
// );
