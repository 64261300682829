import { createAsyncThunk } from "@reduxjs/toolkit";
import { agent, encodeGetParams } from "../../api";
import { apiUrlV1 } from "../../config/config";
import { CaseConfigProfileList, CaseGlobalProfile, CasesProfileList } from "../../entities/cases";

const DEFAULT_URL = `${apiUrlV1}/organization/game/case`;

type ProfilePromise = {
  list: CasesProfileList[],
  count: number,
}

export const getCaseProfiles = createAsyncThunk(
  `case/post-bonus-localizations-by-id`,
  async (): Promise<CasesProfileList[]> => {
    const req = await agent.get(`${DEFAULT_URL}/profile/list`);
    if (req.ok) {
      const res = await req.json() as ProfilePromise;
      return res.list;
    }
    return []
  }
)
export const postProfile = createAsyncThunk(
  'case/post-profile',
  async (data: CaseGlobalProfile, thunkApi): Promise<{ profileId: number }> => {
    const req = await agent.post(`${DEFAULT_URL}/profile/`, {
      body: JSON.stringify(data),
    });
    if (req.ok) {
      const res = await req.json() as { profileId: number }
      thunkApi.dispatch(getCaseProfiles());
      return res;
    }
    return { profileId: 0 };
  }
)
// [POST]/organization/game/case/profile

export const getProfileById = createAsyncThunk(
  'case/get-profile-by-id',
  async (id: number) => {
    const req = await agent.get(`${DEFAULT_URL}/profile/${id}`);
    if (req.ok) {
      const res = req.json();
      return res;
    }
  }
)
// [PATCH]/organization/game/case/profile/{profileId}

// [GET]/organization/game/case/profile/config/list
export const getProfileConfige = createAsyncThunk(
  'case/get-profile-config',
  async (id: number) => {
    const query = encodeGetParams({ profileId: id, page: 1, limit: 250 })
    const req = await agent.get(`${DEFAULT_URL}/profile/config/list${query}`)
    if (req.ok) {
      const res = await req.json() as { list: CaseConfigProfileList[] }
      return res.list;
    }
    return [];
  }
)
// [POST]/organization/game/case/profile/config
export const postProfileConfige = createAsyncThunk(
  'case/post-profile-config',
  async (props: { body: any }) => {
    await agent.post(`${DEFAULT_URL}/profile/config`, {
      body: JSON.stringify(props.body)
    })
  }
)
// [PATCH]/organization/game/case/profile/config/{profileId}
export const patchProfileConfige = createAsyncThunk(
  'case/patch-profile-config',
  async (props: { body: any, id: number }) => {
    await agent.patch(`${DEFAULT_URL}/profile/config/${props.id}`, {
      body: JSON.stringify(props.body)
    })
  }
)
