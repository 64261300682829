import { createAsyncThunk } from "@reduxjs/toolkit";
import { apiUrlV1 } from "../../config/config";
import { agent, encodeGetParams } from "../../api";
import { CaseBonusList, CaseGlobalBonus, ReqBonusLocal } from "../../entities/cases";

const DEFAULT_URL = `${apiUrlV1}/organization/game/case`;

export const getBonusesCase = createAsyncThunk(
  "case/bonus-list",
  async (): Promise<CaseBonusList[]> => {
    const query = encodeGetParams({ page: 1, limit: 250 })
    const req = await agent.get(`${DEFAULT_URL}/bonus/list${query}`);
    if (req.ok) {
      const res = await req.json() as { casePrizeList: CaseBonusList[] }
      return res.casePrizeList;
    }
    return [];
  }
);

export const postBonusCase = createAsyncThunk(
  "case/post-bonus",
  async (data: CaseGlobalBonus) => {
    const req = await agent.post(`${DEFAULT_URL}/bonus`, {
      body: JSON.stringify(data)
    });
    if (req.ok) {
      const res = await req.json() as { id: number };
      return res;
    }
  }
);

export const patchBonusCase = createAsyncThunk(
  "case/patch-bonus",
  async (data: CaseGlobalBonus) => {
    await agent.patch(`${DEFAULT_URL}/bonus`, {
      body: JSON.stringify(data)
    });
  }
);

export const deleteBonusCase = createAsyncThunk(
  `case/delete-bonus`,
  async (id: number, thunkApi) => {
    const req = await agent.delete(`${DEFAULT_URL}/bonus/${id}`);
    if (req.ok) {
      thunkApi.dispatch(getBonusesCase());
    }
  }
)

export const getCaseBonusById = createAsyncThunk(
  "case/get-bonus-by-id",
  async (id: number): Promise<CaseGlobalBonus | null> => {
    const req = await agent.get(`${DEFAULT_URL}/bonus/${id}`);
    if (req.ok) {
      const res = await req.json() as { caseDomainEntity: CaseGlobalBonus };
      return res.caseDomainEntity;
    }
    return null;
  }
);

export const getCaseBonusLocalization = createAsyncThunk(
  `case/get-bonus-localizations-by-id`,
  async (id: number): Promise<ReqBonusLocal[] | []> => {
    const req = await agent.get(`${DEFAULT_URL}/bonus/${id}/content-localization`);
    if (req.ok) {
      const res = await req.json() as ReqBonusLocal[];
      return res;
    }
    return [];
  }
)

export const postCaseBonusLocalization = createAsyncThunk(
  `case/post-bonus-localizations-by-id`,
  async (data: ReqBonusLocal) => {
    await agent.post(`${DEFAULT_URL}/bonus/content-localization`, {
      body: JSON.stringify(data),
    });
  }
)

export const patchCaseBonusLocalization = createAsyncThunk(
  `case/post-bonus-localizations-by-id`,
  async (data: ReqBonusLocal) => {
    await agent.patch(`${DEFAULT_URL}/bonus/content-localization/patch`, {
      body: JSON.stringify(data),
    });
  }
)
