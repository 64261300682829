import { IGame } from "../entities/games";

export const gamesMiddleware = (games: IGame[]):IGame[] => {
  const result = games.map((item) => ({
    name: item.name,
    systemName: item.systemName,
    image: `../Images/logos/${item.systemName}.png`,
  }));
  return [...result];
};
