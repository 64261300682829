import { createAsyncThunk } from "@reduxjs/toolkit";
import { apiUrlV1 } from "../../config/config";
import { agent, encodeGetParams } from "../../api";
import { CaseDisplayResponse, CaseDisplayTranslationResponse, CaseGlobalRequest, CasePricingTranslation, CasesDatabaseList, CaseWinrateResponse, ResponsePostContentLangCase } from "../../entities/cases";
import { ElementOrder, HowToWinGlobal, JackpotDatabaseContent, PopupSettingsForm, ResponseElementOrder, ResponseHowToWin, ResponseHowToWinlangs, ResponsePopupLangs, ResponsePopupSettings } from "../../entities/jackpotGame";

const DEFAULT_URL = `${apiUrlV1}/organization/game/case`;

type CasePrizeListType = {
  casePrizeList: CasesDatabaseList[]
}

export const getCaseDatabases = createAsyncThunk(
  'case/get-database',
  async (): Promise<CasesDatabaseList[]> => {
    const query = encodeGetParams({ page: 1, limit: 250 })
    const req = await agent.get(`${DEFAULT_URL}/list/${query}`);
    if (req.ok) {
      const res = await req.json() as CasePrizeListType;
      return res.casePrizeList;
    }
    return [];
  }
)

export const postCaseGlobal = createAsyncThunk(
  'case/post-global-case',
  async (data: CaseGlobalRequest, thunksApi) => {
    const req = await agent.post(`${DEFAULT_URL}`, {
      body: JSON.stringify(data),
    })
    if (req.ok) {
      const res = await req.json() as { id: number };
      thunksApi.dispatch(getCaseDatabases)
      return res.id;
    }
    return undefined;
  }
)

export const patchCaseGlobal = createAsyncThunk(
  'case/patch-global-case',
  async (data: CaseGlobalRequest, thunksApi) => {
    const req = await agent.patch(`${DEFAULT_URL}/${data.id}`, {
      body: JSON.stringify(data),
    })
    if (req.ok) {
      const res = await req.json() as { id: number };
      thunksApi.dispatch(getCaseDatabases)
      return res.id;
    }
    return undefined;
  }
)

interface CaseDomainEntity {
  caseDomainEntity: CaseGlobalRequest
};
export const getCaseGlobal = createAsyncThunk(
  'case/post-global-case',
  async (id: number, thunksApi) => {
    const req = await agent.get(`${DEFAULT_URL}/${id}`)
    if (req.ok) {
      const res = await req.json() as CaseDomainEntity;
      return res.caseDomainEntity;
    }
    return undefined;
  }
)

export const postCaseContent = createAsyncThunk(
  'case/post-global-content-case',
  async (data: {
    caseId: number,
    caseContentLocalizationGlobalSettings: JackpotDatabaseContent
  }) => {
    await agent.post(`${DEFAULT_URL}/content-localization/create-global-settings`, {
      body: JSON.stringify(data)
    });
  }
)

export const getCaseContent = createAsyncThunk(
  'case/get-content-case',
  async (caseId: number) => {
    const req = await agent.get(`${DEFAULT_URL}/${caseId}/content-localization`);

    if (req.ok) {
      const res = await req.json();
      return res;
    }
    if (!req.ok) {
      return undefined;
    }
  }
)

export const postCaseContentTranslation = createAsyncThunk(
  `case/post-content-translates-case`,
  async (data: {
    caseId: number;
    caseGlobalLocalizationLangConfig: ResponsePostContentLangCase;
  }) => {
    await agent.post(`${DEFAULT_URL}/content-localization/add-lang`, {
      body: JSON.stringify(data),
    })
  }
)

export const patchCaseContentTranslation = createAsyncThunk(
  `case/patch-content-translates-case`,
  async (data: {
    caseId: number;
    caseGlobalLocalizationLangConfig: ResponsePostContentLangCase;
  }) => {
    await agent.post(`${DEFAULT_URL}/content-localization/update-lang`, {
      body: JSON.stringify(data),
    })
  }
)


export const postElementOrder = createAsyncThunk(
  `case/post-page-order`,
  async (props: { data: ElementOrder[]; id: number }) => {
    const { data, id } = props;
    await agent.post(`${DEFAULT_URL}/${id}/element-order`, {
      body: JSON.stringify({ config: data })
    })
  }
)
export const getElementOrder = createAsyncThunk(
  `case/get-page-order`,
  async (id: number) => {
    const res = await agent.get(`${DEFAULT_URL}/${id}/element-order`)
    if (res.ok) {
      const body = await res.json() as ResponseElementOrder;
      return body.config;
    }
  }
)
export const patchElementOrder = createAsyncThunk(
  `case/patch-page-order`,
  async (props: { data: ElementOrder[]; id: number }) => {
    const { data, id } = props;
    const body = {
      config: data.map((item, index) => ({
        title: item.title,
        position: index + 1,
      })),
    };
    await agent.patch(`${DEFAULT_URL}/${id}/element-order`, {
      body: JSON.stringify(body)
    })
  }
)

export const getPopup = createAsyncThunk(
  `case/get-popup-settings`,
  async (id: number): Promise<ResponsePopupSettings | undefined> => {
    const req = await agent.get(`${DEFAULT_URL}/${id}/popup-settings`);
    if (req.ok) {
      const res = (await req.json()) as ResponsePopupSettings;
      return res;
    }
    return undefined;
  }
);

export const postPopupSettings = createAsyncThunk(
  `case/post-popup-settings`,
  async (data: { caseId: number; globalSettings: PopupSettingsForm }) => {
    await agent.post(`${DEFAULT_URL}/popup-settings`, {
      body: JSON.stringify(data),
    });
  }
);

export const patchPopupSettings = createAsyncThunk(
  `case/patch-popup-settings`,
  async (data: { caseId: number; globalSettings: PopupSettingsForm }) => {
    await agent.patch(`${DEFAULT_URL}/popup-settings`, {
      body: JSON.stringify(data),
    });
  }
);

export const postPopupTranslationById = createAsyncThunk(
  "case/post-popup-translation-by-id",
  async (props: { caseId: number; lang: ResponsePopupLangs }) => {
    await agent.post(`${DEFAULT_URL}/popup-settings/add-lang`, {
      body: JSON.stringify(props),
    });
  }
);

export const patchPopupTranslationById = createAsyncThunk(
  "case/patch-popup-translation-by-id",
  async (props: { caseId: number; lang: ResponsePopupLangs }) => {
    await agent.patch(`${DEFAULT_URL}/popup-settings/update-lang`, {
      body: JSON.stringify(props),
    });
  }
);

export const gatHowToWin = createAsyncThunk(
  "case/get-how-to-win",
  async (id: number): Promise<undefined | ResponseHowToWin> => {
    const req = await agent.get(`${DEFAULT_URL}/${id}/how-to-win`);
    if (req.ok) {
      const res = await req.json();
      return res;
    }
    return undefined;
  }
);

export const postHowToWinGlobal = createAsyncThunk(
  "case/post-how-to-win-global",
  async (data: { globalSettings: HowToWinGlobal; caseId: number }) => {
    await agent.post(`${DEFAULT_URL}/how-to-win/create-global-settings`, {
      body: JSON.stringify(data),
    });
  }
);

export const patchHowToWinGlobal = createAsyncThunk(
  "case/patch-how-to-win-global",
  async (data: { globalSettings: HowToWinGlobal; caseId: number }) => {
    await agent.patch(`${DEFAULT_URL}/how-to-win/update-global-settings`, {
      body: JSON.stringify(data),
    });
  }
);

export const postHowToWinTranslation = createAsyncThunk(
  "case/post-how-to-win-translation",
  async (data: { caseId: number; lang: ResponseHowToWinlangs }) => {
    await agent.post(`${DEFAULT_URL}/how-to-win/add-lang`, {
      body: JSON.stringify(data),
    });
  }
);
export const patchHowToWinTranslation = createAsyncThunk(
  "case/patch-how-to-win-translation",
  async (data: { caseId: number; lang: ResponseHowToWinlangs }) => {
    await agent.patch(`${DEFAULT_URL}/how-to-win/update-lang`, {
      body: JSON.stringify(data),
    });
  }
);

export const getWinrate = createAsyncThunk(
  'case/get-win-rate',
  async (caseId: number) => {
    const res = await agent.get(`${DEFAULT_URL}/${caseId}/win-rate`);
    if (res.ok) {
      const body = await res.json() as { config: { bonuses: CaseWinrateResponse[] } };
      return body.config.bonuses;
    }
  }
)

export const postWinrate = createAsyncThunk(
  'case/post-win-rate',
  async (props: { data: CaseWinrateResponse[], caseId: number }) => {
    const { caseId, data } = props;
    const res = await agent.post(`${DEFAULT_URL}/${caseId}/win-rate`, {
      body: JSON.stringify({ bonuses: data })
    });
    if (res.ok) {
      const body = await res.json();
      return body;
    }
  }
)

export const patchWinrate = createAsyncThunk(
  'case/patch-win-rate',
  async (props: { data: CaseWinrateResponse[], caseId: number }) => {
    const { caseId, data } = props;
    const res = await agent.patch(`${DEFAULT_URL}/${caseId}/win-rate`, {
      body: JSON.stringify({ bonuses: data })
    });
    if (res.ok) {
      const body = await res.json();
      return body;
    }
  }
)


export const getDisplay = createAsyncThunk(
  'case/get-display',
  async (caseId: number) => {
    const res = await agent.get(`${DEFAULT_URL}/${caseId}/display-bonus`);

    if (res.ok) {
      const body = await res.json();
      return body;
    }
    return undefined;
  })

export const postDisplay = createAsyncThunk(
  'case/post-display',
  async (props: { data: CaseDisplayResponse[], caseId: number }) => {
    const { data, caseId } = props;
    const res = await agent.post(`${DEFAULT_URL}/${caseId}/display-bonus/create-settings`, {
      body: JSON.stringify({ imageConfigurator: data })
    });

    if (res.ok) {
      const body = await res.json();
      return body;
    }
    return undefined;
  })

export const patchDisplay = createAsyncThunk(
  'case/patch-display',
  async (props: { data: CaseDisplayResponse[], caseId: number }) => {
    const { data, caseId } = props;
    const res = await agent.patch(`${DEFAULT_URL}/${caseId}/${caseId}/display-bonus/update-settings`, {
      body: JSON.stringify({ imageConfigurator: data })
    });

    if (res.ok) {
      const body = await res.json();
      return body;
    }
    return undefined;
  })



export const postDisplayTranslation = createAsyncThunk(
  'case/post-display-translation',
  async (props: { data: CaseDisplayTranslationResponse, caseId: number }) => {
    const { data, caseId } = props;
    await agent.post(`${DEFAULT_URL}/${caseId}/display-bonus/add-lang`, {
      body: JSON.stringify(data)
    });
  })

export const patchDisplayTranslation = createAsyncThunk(
  'case/patch-display-translation',
  async (props: { data: CaseDisplayTranslationResponse, caseId: number }) => {
    const { data, caseId } = props;
    await agent.patch(`${DEFAULT_URL}/${caseId}/display-bonus/update-lang`, {
      body: JSON.stringify(data)
    });
  })

export const getPricing = createAsyncThunk(
  'case/get-Pricing',
  async (caseId: number) => {
    const res = await agent.get(`${DEFAULT_URL}/${caseId}/pricing-and-availability`);

    if (res.ok) {
      const body = await res.json();
      return body;
    }
  }
)

export const postPricing = createAsyncThunk(
  'case/post-pricing',
  async (props: { data: any, caseId: number }) => {
    const { caseId, data } = props;
    await agent.post(`${DEFAULT_URL}/${caseId}/pricing-and-availability`, {
      body: JSON.stringify(data),
    });
  }
)

export const patchPricing = createAsyncThunk(
  'case/patch-pricing',
  async (props: { data: any, caseId: number }) => {
    const { caseId, data } = props;
    await agent.patch(`${DEFAULT_URL}/${caseId}/pricing-and-availability`, {
      body: JSON.stringify(data),
    });
  }
)

export const postPricingTranslation = createAsyncThunk(
  'case/post-pricing-translation',
  async (props: { data: CasePricingTranslation, caseId: number }) => {
    const { caseId, data } = props;
    await agent.post(`${DEFAULT_URL}/${caseId}/pricing-and-availability/add-lang`, {
      body: JSON.stringify(data),
    });
  }
)
export const patchPricingTranslation = createAsyncThunk(
  'case/post-pricing-translation',
  async (props: { data: CasePricingTranslation, caseId: number }) => {
    const { caseId, data } = props;
    await agent.post(`${DEFAULT_URL}/${caseId}/pricing-and-availability/update-lang`, {
      body: JSON.stringify(data),
    });
  }
)

