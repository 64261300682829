import { createSlice, Draft, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "..";

export interface Notificatio {
  text: string,
  status: 'success' | 'info' | 'error'
}

interface NotificationsState {
  notifications: Notificatio[]
}

const initialState: NotificationsState = {
  notifications: []
}

export const notificationSlice = createSlice({
  name: "notification",
  initialState,
  reducers: {
    addNotification: (state: Draft<NotificationsState>, action: PayloadAction<Notificatio>) => {
      state.notifications = [...state.notifications, action.payload];
    },
    shiftNotification: (state: Draft<NotificationsState>) => {
      const result = state.notifications;
      result.shift();
      state.notifications = result;
    }
  },
});

export const { addNotification,
  shiftNotification } = notificationSlice.actions;

export const selectNotifications = (state: RootState) => state.notification;

export default notificationSlice.reducer;
