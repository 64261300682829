import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { useDispatch, useSelector, TypedUseSelectorHook } from 'react-redux';
import authSlice from './Auth/authStore';
import logger from 'redux-logger';
import bonusSlice from './Bonus/bonusStore';
import triggerSlice from './Trigger/triggerStore';
import wheelSlice from './WheelGame/wheelStore';
import gamesSlice from './Games/gamesStore'
import jackpotsSlice from './JackpotGame/jackpotStore';
import jackpotSlice from './JackpotGame/Jackpot/jackpotStore'
import jackpotProfileSlice from './JackpotGame/Jackpot/profileStore'
import caseSlice from './CaseGame/caseStore'
import notificationSlice from './Notification/notificateStore'

const jackpotReducer = combineReducers({
  tables: jackpotsSlice,
  jackpot: jackpotSlice,
  profile: jackpotProfileSlice
})

const rootReducer = combineReducers({
  auth: authSlice,
  bonus: bonusSlice,
  trigger: triggerSlice,
  games: gamesSlice,
  wheel: wheelSlice,
  jackpot: jackpotReducer,
  case: caseSlice,
  notification: notificationSlice,
});

const store = configureStore({
  reducer: rootReducer,
  middleware: getDefaultMiddleware => process.env.NODE_ENV === 'development'
    ? getDefaultMiddleware().concat(logger)
    : getDefaultMiddleware()
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export default store;