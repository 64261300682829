
export type Lang = 'ru' | 'en' | 'ru-RU' | 'en-EN';

export const apiUrlV1: string = process.env.REACT_APP_API_URL_V1 || '';
export const publicUrl: string = process.env.PUBLIC_URL || '';
export const publicSiteUrl: string = process.env.REACT_APP_PUBLIC_URL || '';
export const languageLocalStorageKey: string = (process.env.REACT_APP_LANGUAGE_LOCAL_STORAGE_KEY || 'language');
export const mainLanguage: Lang = (process.env.REACT_APP_MAIN_LANGUAGE || 'en') as Lang;

export const apiKeyFirebase: string = process.env.REACT_APP_FIREBASE_API_KEY || '';

export const firebaseConfig= {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY || '',
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN || '',
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID || '',
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET || '',
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDERING || '',
    appId: process.env.REACT_APP_FIREBASE_APP_ID || '',
    measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID || ''
};

export const widgetUrl = process.env.REACT_APP_WIDGET_URL || 'https://widget.gamixter.com/assets/widget.js'