// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from 'firebase/auth'
import {firebaseConfig} from "../config/config";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
// const firebaseConfig = {
//   apiKey: "AIzaSyAz8e4saIjTGg3R8DU3ott1KaarQZafqOM",
//   authDomain: "gamemixter-b6f0e.firebaseapp.com",
//   projectId: "gamemixter-b6f0e",
//   storageBucket: "gamemixter-b6f0e.appspot.com",
//   messagingSenderId: "1021254972415",
//   appId: "1:1021254972415:web:25da17eb0d89bc81462dca",
//   measurementId: "G-J7NLLJ2FY2"
// };

// Initialize Firebase
const app = initializeApp(firebaseConfig);

export const auth = getAuth(app);