import {
  NotificationGlobal,
  NotificationTranslation,
  RequestNotificationGlobal,
  ResponseNotificationLangs,
} from "../../entities/jackpotGame";

export const parseNotifGlobal = (
  data: RequestNotificationGlobal
): NotificationGlobal => {
  return {
    nonParticipantsPageLink:
      data.jackpotCompletionPopupForNonParticipants.popupPageLink,
    nonWinningPageLink:
      data.jackpotCompletionPopupForNonWinningParticipants.popupPageLink,
    winnerPageLink: data.jackpotWinnerPopup.popupPageLink,
  };
};

export const parseNotifGlobalToPost = (
  data: NotificationGlobal
): RequestNotificationGlobal => {
  return {
    jackpotCompletionPopupForNonParticipants: {
      popupPageLink: data.nonParticipantsPageLink,
    },
    jackpotCompletionPopupForNonWinningParticipants: {
      popupPageLink: data.nonWinningPageLink,
    },
    jackpotWinnerPopup: { popupPageLink: data.winnerPageLink },
  };
};

export const parseNotificationTranslation = (
  data: ResponseNotificationLangs
): NotificationTranslation => {
  const {
    jackpotCompletionPopupForNonParticipants: NP,
    jackpotCompletionPopupForNonWinningParticipants: NWP,
    jackpotWinnerPopup: Win,
  } = data;
  return {
    NPTitle: NP.pageTitle,
    NPSubtitle: NP.pageSubtitle,
    NPButton: NP.buttonText,
    NPImage: NP.pageImage,
    NWPTitle: NWP.pageTitle,
    NWPSubtitle: NWP.pageSubtitle,
    NWPButton: NWP.buttonText,
    NWPImage: NWP.pageImage,
    WinTitle: Win.pageTitle,
    WinSubtitle: Win.pageSubtitle,
    WinButton: Win.buttonText,
    WinImage: Win.pageImage,
  };
};

export const parseNotificationTranslationToPost = (
  props: { lang: string; data: NotificationTranslation },
  jackpotId: number
): { jackpotId: number; lang: ResponseNotificationLangs } => {
  const { data, lang } = props;
  return {
    jackpotId,
    lang: {
      title: "",
      lang: lang,
      jackpotCompletionPopupForNonParticipants: {
        pageTitle: data.NPTitle,
        pageSubtitle: data.NPSubtitle,
        buttonText: data.NPButton,
        pageImage: data.NPImage,
      },
      jackpotCompletionPopupForNonWinningParticipants: {
        pageTitle: data.NWPTitle,
        pageSubtitle: data.NWPSubtitle,
        buttonText: data.NWPButton,
        pageImage: data.NWPImage,
      },
      jackpotWinnerPopup: {
        pageTitle: data.WinTitle,
        pageSubtitle: data.WinSubtitle,
        buttonText: data.WinButton,
        pageImage: data.WinImage,
      },
    },
  };
};
