import { createSlice } from "@reduxjs/toolkit";
import { CaseBonusList, CaseGlobalBonus, CaseGlobalProfile, CasesDatabaseList, CasesProfileList, ReqBonusLocal } from "../../entities/cases";
import { RootState } from "..";
import { getBonusesCase, getCaseBonusById, getCaseBonusLocalization } from "./bonusThunks";
import { getCaseProfiles, getProfileById } from "./profileThunks";
import { getCaseDatabases } from "./databaseThunks";

interface CaseState {
  loading: {
    bonus: boolean,
    profile: boolean,
    database: boolean
  },
  list: {
    bonuses: CaseBonusList[],
    profiles: CasesProfileList[],
    databases: CasesDatabaseList[],
  },
  bonus?: CaseGlobalBonus,
  bonusLocalization?: ReqBonusLocal[],
  profile?: CaseGlobalProfile,

}

const initialState: CaseState = {
  loading: {
    bonus: false,
    profile: false,
    database: false,
  },
  list: {
    bonuses: [],
    profiles: [],
    databases: [],
  }
}

export const caseSlice = createSlice({
  name: "case",
  initialState,
  reducers: {
    clearEditBonus: (state) => {
      state.bonus = undefined;
      state.bonusLocalization = undefined;
    },
    clearEditProfile: (state) => {
      state.profile = undefined;
      // state.bonusLocalization = undefined;
    }
  },
  extraReducers: (builder) => {
    // Bonuses
    builder.addCase(getBonusesCase.pending, (state) => {
      state.loading.bonus = true;
    })
    builder.addCase(getBonusesCase.fulfilled, (state, action) => {
      state.loading.bonus = false;
      state.list.bonuses = action.payload.sort((a, b) => a.id - b.id);
    })

    builder.addCase(getCaseBonusById.fulfilled, (state, action) => {
      if (action.payload)
        state.bonus = action.payload;
    })

    builder.addCase(getCaseBonusLocalization.fulfilled, (state, action) => {
      state.bonusLocalization = action.payload;
    })

    // Profiles
    builder.addCase(getCaseProfiles.pending, (state) => {
      state.loading.profile = true;
    })
    builder.addCase(getCaseProfiles.fulfilled, (state, action) => {
      state.loading.profile = false;
      state.list.profiles = action.payload.sort((a, b) => a.id - b.id);
    })
    builder.addCase(getProfileById.fulfilled, (state, action) => {
      state.profile = action.payload;
    })

    // Databases
    builder.addCase(getCaseDatabases.pending, (state) => {
      state.loading.database = true;
    })
    builder.addCase(getCaseDatabases.fulfilled, (state, action) => {
      state.loading.database = false;
      state.list.databases = action.payload.sort((a, b) => a.id - b.id);
    })
  },
});

export const { clearEditBonus, clearEditProfile } = caseSlice.actions;

export const selectCaseLoading = (state: RootState) => state.case.loading;
export const selectCaseLists = (state: RootState) => state.case.list;

export default caseSlice.reducer;
